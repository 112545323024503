import $ from "jquery";
import { Controller } from "@hotwired/stimulus";
import { setUrlParameterAndReload } from "../src/custom/UrlParams";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    const { innerWidth } = window;
    const positioning = innerWidth <= 500 ? "bottom center" : "bottom right";
    if (innerWidth <= 500) {
      this.menuTarget.classList.add("m-w-full", "tw-z-full");
    }

    if (
      this.menuTarget.classList.contains("visible") &&
      !this.menuTarget.classList.contains("mutated")
    ) {
      const langPickerBtnRect = this.element.getBoundingClientRect();
      this.menuTarget.classList.add("mutated");
      this.menuTarget.style.setProperty(
        "--arrow-pos",
        `${langPickerBtnRect.left + langPickerBtnRect.width / 4}px`,
      );
    } else if (
      !this.menuTarget.classList.contains("visible") &&
      this.menuTarget.classList.contains("mutated")
    ) {
      this.menuTarget.classList.remove("mutated");
    }

    $(this.menuTarget)
      .closest(".language_picker__btn")
      .popup({
        position: positioning,
        preserve: true,
        lastResort: true,
        transition: "fade down",
        popup: $(this.menuTarget),
        on: "click",
        closable: false,
      });
  }

  changeLanguage(event) {
    event.preventDefault();
    setUrlParameterAndReload("locale", event.params.language);
    event.stopImmediatePropagation();
  }
}
